import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HTTP from 'i18next-fetch-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Locize from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import supportedLanguages from './supportedLanguages';

const isDevelopment = process.env.NODE_ENV === 'development';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    load: 'all',
    nonExplicitSupportedLngs: false,
    fallbackLng: {
      no: ['nb-NO', 'en'],
      nb: ['nb-NO', 'en'],
      zh: ['zh-Hans', 'en'],
      'zh-CN': ['zh-Hans', 'en'],
      'zh-TW': ['zh-Hant', 'en'],
      default: ['en'],
    },
    supportedLngs: supportedLanguages, // @TODO: Find more "dynamic" way to set supported languages
    detection: {
      order: ['querystring', 'path', 'cookie', 'sessionStorage', 'navigator', 'htmlTag'],
    },
    interpolation: {
      escapeValue: false,
    },
    ns: ['common', 'cookies', 'map', 'contactForm', 'testimonials', 'calculator', 'offsetApp'],
    returnNull: false,
    returnEmptyString: false,
    react: {
      useSuspense: false,
    },
    debug: isDevelopment,
    backend: {
      backends: [
        ...(isDevelopment ? [LocalStorageBackend] : []), // primary
        Locize, // primary
        HTTP, // fallback
      ],
      backendOptions: [
        ...(isDevelopment
          ? [
              {
                prefix: 'i18_',
                expirationTime: parseInt(process.env.GATSBY_LOCIZE_EXPIRATION ?? '3600000'), // cache
              },
            ]
          : []),
        {
          projectId: process.env.GATSBY_LOCIZE_PROJECT_ID,
          apiKey: process.env.GATSBY_LOCIZE_API_KEY,
          version: process.env.GATSBY_LOCIZE_PROJECT_VERSION,
          failLoadingOnEmptyJSON: true,
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export default i18n;
