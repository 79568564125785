export function removeURLParameter(url: string, parameter: string, hash = false) {
  const sign = hash ? '#' : '?';
  const urlparts = url.split(sign);
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    const pars = urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? sign + pars.join('&') : '');
  }
  return url;
}
