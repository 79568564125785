import { EnvConfig } from '@env-config';
import { useEffect, useState } from 'react';

type useClientGeoLocationOptions = {
  onFetchSuccess?: (position: GeoLocation) => void;
  onFetchError?: (error: unknown) => void;
};

const useClientGeoLocation = (options?: useClientGeoLocationOptions) => {
  const [userGeoLocation, setUserGeoLocation] = useState<GeoLocation | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const fetchLocation = async () => {
    const IP_API_URL = 'https://location.chooose.today/ip/';

    try {
      const response = await fetch(IP_API_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch location data');
      }
      const data: GeoLocation = await response.json();
      const cacheData = {
        data,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('userGeoLocation', JSON.stringify(cacheData));
      setUserGeoLocation(data);
      options?.onFetchSuccess?.(data);
    } catch (error) {
      setError('Unable to fetch location data');
      console.error(error);
      options?.onFetchError?.(error);
    }
  };

  const getClientLocation = () => {
    const cache = localStorage.getItem('userGeoLocation');
    const cachedData = cache ? JSON.parse(cache) : null;

    if (cachedData) {
      const isExpired = new Date().getTime() - cachedData.timestamp > 10800000; // 3 hours
      if (!isExpired) {
        setUserGeoLocation(cachedData.data);
        options?.onFetchSuccess?.(cachedData.data);
      } else {
        fetchLocation();
      }
    } else {
      fetchLocation();
    }
  };

  useEffect(() => {
    getClientLocation();
  }, []);

  return { userGeoLocation, error };
};

export default useClientGeoLocation;
