import React, { useState, createContext, useCallback, useEffect, useContext } from 'react';
import { getPartnership } from '../services/partnership';
import { OffsetAppType } from '../sanityComponents/OffsetApp/OffsetAppContext';
import { useToast } from '@chakra-ui/react';
import useClientGeoLocation from 'sanityComponents/OffsetApp/common/hooks/useClientLocation';
import { useTranslation } from 'react-i18next';

const DEFAULT_SUPPORT_EMAIL = 'support@chooose.today';

interface IProps {
  children: React.ReactNode;
}

interface State {
  activeBonusRule?: PartnershipBonusRule;
  partnerName: string;
  partnershipId: string;
  loading: boolean;
  allowedAirlineCodes: string[];
  airportDataSet: string;
  multiplier?: {
    value: number;
  };
  programmeName?: string | null;
  portalUrl?: string | null;
  userGeoLocation?: GeoLocation | null;
  offsetAppType?: string;
  offsetAppSlug?: string;
}

interface ContextState extends State {
  setOffsetAppType: (type: OffsetAppType, isMulti: boolean) => void;
  setOffsetAppSlug: (slug: string) => void;
}

const initialState: State = {
  partnerName: '',
  partnershipId: '',
  allowedAirlineCodes: [],
  airportDataSet: '',
  loading: false,
};

const Context = createContext<ContextState>(initialState as ContextState);

const GlobalContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setLoading = (value: boolean) => setState(prevState => ({ ...prevState, loading: value }));

  const fetchPartnership = useCallback(async () => {
    setLoading(true);
    try {
      const { name = '', allowedAirlineCodes = [], airportDataSet = '', id, ...props } = await getPartnership();
      setState(prevState => ({
        ...prevState,
        partnerName: name,
        partnershipId: id,
        allowedAirlineCodes,
        airportDataSet,
        ...props,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [setState]);

  const setOffsetAppType = useCallback(
    (type: OffsetAppType, isMulti: boolean) => {
      const offsetAppType = type === 'blended' ? (isMulti ? 'blended multi portfolios' : 'blended single portfolio') : type;
      setState(prevState => ({ ...prevState, offsetAppType }));
    },
    [setState],
  );

  const setOffsetAppSlug = useCallback(
    (slug: string) => {
      setState(prevState => ({ ...prevState, offsetAppSlug: slug }));
    },
    [setState],
  );

  const toast = useToast();
  const { t } = useTranslation('offsetApp');
  const setClientLocation = useCallback((value: GeoLocation) => setState(prevState => ({ ...prevState, userGeoLocation: value })), []);

  const notifyAboutGeoLocationError = () => {
    toast({
      position: 'bottom-right',
      title: userGeoLocation?.message || t('common.generalLoadError'),
      status: 'error',
      isClosable: true,
    });
    console.error("Error while fetching user's location");
  };

  const { userGeoLocation } = useClientGeoLocation({
    onFetchSuccess: setClientLocation,
    onFetchError: notifyAboutGeoLocationError,
  });

  useEffect(() => {
    fetchPartnership();
  }, []);

  return <Context.Provider value={{ ...state, setOffsetAppType, setOffsetAppSlug }}>{children}</Context.Provider>;
};

const useGlobalContext = (): ContextState => useContext(Context);

export { GlobalContextProvider, useGlobalContext, DEFAULT_SUPPORT_EMAIL };
