type EnvKey = keyof ReturnType<typeof getConfig>;

const getConfig = () => {
  return {
    CHOOOSE_API_FUNCTION_KEY: process.env.GATSBY_API_FUNCTION_KEY,
    CHOOOSE_PAGE_SLUG: process.env.GATSBY_PAGE_SLUG,
    CHOOOSE_API_URL: process.env.GATSBY_API_URL,
    CHOOOSE_PARTNERSHIP_ID: process.env.GATSBY_PARTNERSHIP_ID,
    CHOOOSE_CUSTOMER_ID: process.env.GATSBY_CUSTOMER_ID,
    CHOOOSE_PORTAL_URL: process.env.GATSBY_PORTAL_URL,
    CHOOOSE_WIDGET_URL: process.env.GATSBY_WIDGET_URL,
    CHOOOSE_VERSION_TAG: process.env.GATSBY_VERSION_TAG,

    SANITY_PROJECT_ID: process.env.GATSBY_SANITY_PROJECT_ID,
    SANITY_DATASET: process.env.GATSBY_SANITY_DATASET,
    SANITY_TOKEN: process.env.GATSBY_SANITY_TOKEN,
    SANITY_GRAPHQL_TAG: process.env.GATSBY_SANITY_GRAPHQL_TAG,

    LOCIZE_PROJECT_ID: process.env.GATSBY_LOCIZE_PROJECT_ID,
    LOCIZE_API_KEY: process.env.GATSBY_LOCIZE_API_KEY,
    LOCIZE_PROJECT_VERSION: process.env.GATSBY_LOCIZE_PROJECT_VERSION,
    LOCIZE_EXPIRATION: process.env.GATSBY_LOCIZE_EXPIRATION,

    PLASMIC_ID: process.env.GATSBY_LASMIC_ID,
    PLASMIC_TOKEN: process.env.GATSBY_PLASMIC_TOKEN,
    PLASMIC_CMS_ID: process.env.GATSBY_PLASMIC_CMS_ID,
    PLASMIC_CMS_PUBLIC_TOKEN: process.env.GATSBY_PLASMIC_CMS_PUBLIC_TOKEN,
    PLASMIC_CMS_SECRET_TOKEN: process.env.GATSBY_PLASMIC_CMS_SECRET_TOKEN,
    PLASMIC_CMS_URL: process.env.GATSBY_PLASMIC_CMS_URL,

    APP_INSIGHTS_CONNECTION_STRING: process.env.GATSBY_APP_INSIGHTS_CONNECTION_STRING,
    DISABLE_APP_INSIGHTS: process.env.GATSBY_DISABLE_APP_INSIGHTS,
    POSTHOG_API_KEY: process.env.GATSBY_POSTHOG_API_KEY,
    POSTHOG_API_HOST: process.env.GATSBY_POSTHOG_API_HOST,

    STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
    GOOGLE_MAPS_API_KEY: process.env.GATSBY_GOOGLE_API_KEY,
    SALESFORCE_ORG_ID: process.env.GATSBY_SALESFORCE_ORG_ID,
    IS_PLASMIC: process.env.IS_PLASMIC,

    DEV: process.env.DEV,
  };
};

export class EnvConfig {
  constructor(private readonly config = getConfig()) {}
  get(key: EnvKey): string {
    return this.config[key] ?? '';
  }
}
